import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Divider from '../components/Divider'
import FloatingImage from '../components/FloatingImage'
import FadeIn from '../components/FadeIn'
import BeforeAndAfter from '../components/BeforeAndAfter'

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      eyeliner_1_before: file(
        relativePath: { eq: "before-and-after/eyeliner-1-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_1_after: file(
        relativePath: { eq: "before-and-after/eyeliner-1-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_1_before: file(
        relativePath: { eq: "before-and-after/eyebrows-1-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_1_after: file(
        relativePath: { eq: "before-and-after/eyebrows-1-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_1_before: file(
        relativePath: { eq: "before-and-after/lips-1-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_1_after: file(
        relativePath: { eq: "before-and-after/lips-1-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_1_before: file(
        relativePath: {
          eq: "before-and-after/areola-1-before.jpg"
        }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_1_after: file(
        relativePath: {
          eq: "before-and-after/areola-1-after.jpg"
        }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Services" />
      <section id="intro">
        <FadeIn className="lede" duration={0.5}>
          <p>
            We offer a variety of permanent cosmetics services including
            eyeliner, eyebrows, lips, and areola and skin repigmentation.
            Permanent Cosmetic procedure fees are $500.00 (plus Washington State
            sales tax) per procedure. Up to three free touch ups until the
            anniversary date has been reached. Touch up fees are charged
            thereafter.
          </p>
        </FadeIn>
      </section>
      <Divider />
      <section id="eyeliner" className="spaced">
        <FadeIn className="text">
          <h2>Eyeliner</h2>
          <p>
            Eyeliner can be applied to suit your individual needs. If you desire
            a fine line through the lashes, a soft smudge, or heavier line for a
            more dramatic look, permanent eyeliner is the answer.{' '}
          </p>
          <p>
            Permanent eyeliner can also be applied to reshape the eyes or even
            make small eyes appear larger.
          </p>
          <p>
            Why bother with the daily task of applying eyeliner and trying to
            get it even? Wake up each morning without makeup smudged on your
            face.
          </p>
          <p>
            Go swimming, skiing, exercising, etc. without worrying about how
            your makeup looks. <em>It will always look great!</em>
          </p>
        </FadeIn>
        <FloatingImage>
          <BeforeAndAfter
            beforeImg={data.eyeliner_1_before.childImageSharp}
            beforeAlt="Eyeliner permanent cosmetics, before photo"
            afterImg={data.eyeliner_1_after.childImageSharp}
            afterAlt="Eyeliner permanent cosmetics, after photo"
          />
        </FloatingImage>
      </section>
      <section id="eyebrows" className="spaced">
        <FloatingImage>
          <BeforeAndAfter
            beforeImg={data.eyebrows_1_before.childImageSharp}
            beforeAlt="Eyebrows permanent cosmetics, before photo"
            afterImg={data.eyebrows_1_after.childImageSharp}
            afterAlt="Eyebrows permanent cosmetics, after photo"
          />
        </FloatingImage>
        <FadeIn className="text">
          <h2>Eyebrows</h2>
          <p>
            This procedure is a miracle for those who must pencil in their
            eyebrows everyday. Reshaping the eyebrows can be done with the small
            hair-like stroked to blend in with existing eyebrow hairs, or to
            fill in a full brow where no hair exists.
          </p>
          <p>
            Eyebrows frame to eyes and are essential to balance your facial
            features.
          </p>
          <p>
            Permanent eyebrows do not wash off in the shower or rub off on
            clothes, bedding, or your eyeglasses.
          </p>
        </FadeIn>
      </section>
      <section id="lips" className="spaced">
        <FadeIn className="text">
          <h2>Lips</h2>
          <p>
            Lipliner outlines and defines the lips. Lips that are uneven, thin,
            or pale may be reshaped to look full and beautiful. A bow in the
            upper lip can be created where there once was none. Irregularities
            in the lip due to cold sores can easily be corrected with permanent
            lipliner.
          </p>
          <p>
            A lip fill is the process of tinting the entire lip area with color.
            If you dislike lipstick that never stays on, full lip color can be
            applied. You can choose a natural hue or a more vivid color.
          </p>
        </FadeIn>
        <FloatingImage>
          <BeforeAndAfter
            beforeImg={data.lips_1_before.childImageSharp}
            beforeAlt="Lips permanent cosmetics, before photo"
            afterImg={data.lips_1_after.childImageSharp}
            afterAlt="Lips permanent cosmetics, after photo"
          />
        </FloatingImage>
      </section>
      <section id="areolas">
        <FloatingImage>
          <BeforeAndAfter
            beforeImg={data.areola_1_before.childImageSharp}
            beforeAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
            afterImg={data.areola_1_after.childImageSharp}
            afterAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
          />
        </FloatingImage>
        <FadeIn className="text">
          <h2>Repigmentation</h2>
          <p>
            This procedure is a miracle for those who must pencil in their
            eyebrows everyday. Reshaping the eyebrows can be done with the small
            hair-like stroked to blend in with existing eyebrow hairs, or to
            fill in a full brow where no hair exists.
          </p>
          <p>
            Eyebrows frame to eyes and are essential to balance your facial
            features.
          </p>
          <p>
            Permanent eyebrows do not wash off in the shower or rub off on
            clothes, bedding, or your eyeglasses.
          </p>
        </FadeIn>
      </section>
      <Divider />
    </Layout>
  )
}
export default ServicesPage
